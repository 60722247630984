import { mode } from "@chakra-ui/theme-tools";

export const selectStyles = {
  components: {
    Select: {
      variants: {
        primary: (props) => ({
          field: {
            bg: mode("sky.light", "navy.light")(props),
            color: mode("black", "white")(props),
            height: "65px",
            textAlign: "center",
            borderRadius: "xl",
            _placeholder: { color: "#747474" },
            _disabled: {
              opacity: 0.5,
            },
          },
        }),
      },
    },
  },
};
