import routes from "src/routes";
import ray from "src/config/ray";

//icons
import { FiUsers, FiUser, FiKey, FiGitPullRequest } from "react-icons/fi";
import { AiOutlineSetting } from "react-icons/ai";
import { IoAccessibilityOutline, IoDocumentsOutline } from "react-icons/io5";
import { BsDatabaseDown, BsDatabaseUp } from "react-icons/bs";
import { IoPricetagsOutline } from "react-icons/io5";
import { BiSelectMultiple, BiMaleFemale } from "react-icons/bi";
import { CgProfile } from "react-icons/cg";
import { FaChildren } from "react-icons/fa6";
import { TbReportSearch } from "react-icons/tb";
import {
  HiOutlineOfficeBuilding,
  HiOutlineIdentification,
} from "react-icons/hi";

const routesList =
  routes.find((page) => page.hasOwnProperty("children"))?.children || [];
const dashboardTabs = routesList.map((u) =>
  Object.assign({}, u, { approved: true })
);

dashboardTabs.map((item) => {
  switch (item.key) {
    case "users":
      item.title = "کاربران";
      item.icon = <FiUsers />;
      break;
    case "profile":
      item.title = "نمایه کاربری";
      item.icon = <CgProfile />;
      item.hasOwnProperty("children") &&
        item.children.map((item) => {
          switch (item.key) {
            case "profileUserInfo":
              item.title = "مشخصات فردی";
              item.icon = <FiUser />;
              break;
            case "profilePersonelInfo":
              item.title = "مشخصات پرسنلی";
              item.icon = <HiOutlineIdentification />;
              break;
            case "profileWifeInfo":
              item.title = "مشخصات همسر";
              item.icon = <BiMaleFemale />;
              break;
            case "profileChildrenInfo":
              item.title = "مشخصات فرزند";
              item.icon = <FaChildren />;
              break;
            default:
              break;
          }
        });
      break;
    case "permissions":
      item.title = "مجوز دسترسی";
      item.icon = <FiKey />;
      break;
    case "roles":
      item.title = "نقش کاربری";
      item.icon = <IoAccessibilityOutline />;
      break;
    case "offices":
      item.title = "اداره ها";
      item.icon = <HiOutlineOfficeBuilding />;
      break;
    case "proccesses":
      item.title = "فرایند ها";
      item.icon = <FiGitPullRequest />;
      break;
    case "requests":
      item.title = "درخواست ها";
      item.icon = <FiGitPullRequest />;
      const explodedRequestsChildren = [];
      item.children = item.children?.filter((child) => {
        child.icon = <FiGitPullRequest />;
        switch (child.key) {
          case "create-request":
            child.title = "ایجاد درخواست";
            return true;
          case "requests":
            if (child.availableParams) {
              child.availableParams.forEach((param) => {
                const paramObject = {
                  directory: child.key,
                  url: param,
                  key: param,
                };
                paramObject.icon = <FiGitPullRequest />;
                switch (param) {
                  case "all":
                    paramObject.title = "کارتابل صندوق";
                    break;
                  case "role-related":
                    paramObject.title = "کارتابل من";
                    break;
                  default:
                    break;
                }
                explodedRequestsChildren.push(paramObject);
              });
              return false;
            }
            return true;
          default:
            return true;
        }
      });
      item.children.push(...explodedRequestsChildren);
      break;
    case "forms":
      item.title = "فرم ها";
      item.icon = <IoDocumentsOutline />;
      break;
    case "customlist":
      item.title = "لیست داده";
      item.icon = <IoDocumentsOutline />;
      break;
    case "reports":
      item.title = "گزارشات";
      item.icon = <TbReportSearch />;
      break;
    case "config":
      item.title = "تنظیمات";
      item.icon = <AiOutlineSetting />;
      break;
    case "instalments":
      item.title = "اقساط";
      item.icon = <IoPricetagsOutline />;
      break;
    case "batch-actions":
      item.title = "عملیات تجمیعی";
      item.icon = <BiSelectMultiple />;
      const explodedBatchActionsChildren = [];
      item.children = item.children?.filter((child) => {
        child.icon = <FiGitPullRequest />;
        switch (child.key) {
          case "batch-actions":
            if (child.availableParams) {
              child.availableParams.forEach((param) => {
                const paramObject = {
                  directory: child.key,
                  url: param,
                  key: param,
                };
                switch (param) {
                  case "import":
                    paramObject.title = "ورودی";
                    paramObject.icon = <BsDatabaseDown />;
                    break;
                  case "export":
                    paramObject.title = "خروجی";
                    paramObject.icon = <BsDatabaseUp />;
                    break;
                  default:
                    break;
                }
                explodedBatchActionsChildren.push(paramObject);
              });
              return false;
            }
            return true;
          default:
            return true;
        }
      });
      item.children.push(...explodedBatchActionsChildren);
      break;
    default:
      break;
  }
});

const config = {
  axiosBaseUrl: process.env.REACT_APP_URL,
  appPage: process.env.REACT_APP_PAGE,
  production: process.env.REACT_APP_ENV === "production" ? true : false,
  // staticDirectory: process.env.REACT_APP_CDN,
  dashboardTabs,
  version: "2.0.0",
  ray,
};

export default config;
