import { API, URL } from "src/config/api";

export const getData = async (obj) => {
  try {
    const response = await API.get(URL.offices);
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject({ err, status: err.response.status });
  }
};

export const getSubData = async (obj) => {
  try {
    const response = await API.get(URL.subOffices);
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject({ err, status: err.response.status });
  }
};

export const getUsersByOffice = async (id) => {
  try {
    const response = await API.get(`${URL.offices}/users/${id}`);
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject({ err, status: err.response.status });
  }
};

export const createData = async (data) => {
  try {
    const response = await API.post(`${URL.offices}`, data);
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const updateData = async (id, data) => {
  try {
    const response = await API.put(`${URL.offices}/${id}`, data);
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteData = async (id) => {
  try {
    const response = await API.delete(`${URL.offices}/${id}`);
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject(err);
  }
};
