import {
  SET_CURRENT_USER,
  LOG_OUT_USER,
  SET_USER_ERROR,
  SET_USER_LOADING,
  SET_USER_DATA,
} from "src/redux/constants";

const initialState = {
  isAuthenticated: false,
  data: null,
  error: null,
  isFetching: false,
};

export default function (state = initialState, action) {
  const storedData = { ...state };
  const payload = action.payload;
  switch (action.type) {
    case SET_CURRENT_USER:
      storedData.isAuthenticated = true;
      storedData.isFetching = false;
      storedData.data = payload;
      return storedData;
    case SET_USER_DATA:
      storedData.data = payload;
      return storedData;
    case LOG_OUT_USER:
      storedData.isAuthenticated = false;
      storedData.data = initialState.data;
      return storedData;
    case SET_USER_ERROR:
      storedData.error = payload;
      storedData.isFetching = false;
      return storedData;
    case SET_USER_LOADING:
      storedData.isFetching = payload;
      return storedData;
    default:
      return state;
  }
}
