const activeLabelStyles = {
  transform: "scale(0.85) translateY(-28px)",
};

export const formStyles = {
  components: {
    Form: {
      variants: {
        floating: {
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles,
              },
            },
            "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label":
              {
                ...activeLabelStyles,
              },
            label: {
              top: "15px",
              right: "5px",
              zIndex: 2,
              position: "absolute",
              backgroundColor: "white",
              pointerEvents: "none",
              mx: "5px",
              px: "10px",
              transformOrigin: "right top",
            },
          },
        },
      },
    },
  },
};
