export const formLabelStyles = {
  components: {
    FormLabel: {
      baseStyle: {
        fontSize: "sm",
        // marginBottom: "10px",
        // color: "red.dark",
      },
    },
  },
};
