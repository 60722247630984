//config
import { API } from "src/config/api";

//packages
import imageCompression from "browser-image-compression";
import moment from "moment-jalaali";
import _ from "underscore";
import RayID from "rayid";

//services
import { uploadFile, deleteFile } from "src/services/api/files";

const compressionConf = {
  maxWidthOrHeight: 1280,
  useWebWorker: true,
};

export const toFarsiNumber = (value = "") => {
  let nVal = value.toString();
  const enNum = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];
  const FaNum = ["۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹", "۰"];

  for (let i = 0, numbersLen = enNum.length; i < numbersLen; i++) {
    nVal = nVal.replace(new RegExp(enNum[i], "g"), FaNum[i]);
  }
  return nVal;
};

export const numberWithCommas = (number) => {
  if (!number) return;
  return number
    .toString()
    .replace(/[^0-9]/g, "")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
export const numberWithDash = (number) => {
  if (!number) return;
  return number
    .toString()
    .replace(/[^0-9]/g, "")
    .replace(/\B(?=(\d{4})+(?!\d))/g, "-");
};

const isLeapShamsiYear = (year) => {
  return year % 4 === 0 && year % 100 !== 0;
};

export const diffDate = (dateTime) => {
  if (typeof dateTime === "string" && dateTime.length === 10) {
    const customDate = moment(dateTime, "jYYYY/jMM/jDD");
    const currentDate = moment().startOf("day");

    const diffInMonths = currentDate.jMonth() - customDate.jMonth();
    const diffInYears = currentDate.jYear() - customDate.jYear();

    if (diffInMonths < 0) {
      diffInMonths += 12;
    }

    const daysInShamsiYear = isLeapShamsiYear(customDate.jYear()) ? 366 : 365;

    const remainingDaysInYear = currentDate
      .clone()
      .subtract(diffInYears, "jYear")
      .diff(customDate, "days");
    const decimalPartOfYear = remainingDaysInYear / daysInShamsiYear;

    const totalYears = diffInYears + decimalPartOfYear;

    return totalYears.toFixed(4);
  } else {
    return "";
  }
};

export const currency = (value, showUnit = true) => {
  if (!value) {
    return showUnit ? "۰ ریال" : "۰";
  }

  value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  if (showUnit) {
    value += " ریال";
  }
  return toFarsiNumber(value);
};

export const isValidPhoneNumber = (value) => {
  const regex = new RegExp("^(\\+98|0)?9\\d{9}$");
  const result = regex.test(value);
  return result;
};

export const setApiHeaders = (obj) => {
  API.defaults.headers.common["ctx"] = JSON.stringify(obj);
};

export const curlParser = (curl, onlyEnKeys = false) => {
  const regex = /\|(.*?)\|/g;
  let keys = curl.match(regex);
  if (onlyEnKeys) {
    keys = keys
      .map((key) => key.replaceAll("|", ""))
      .map((key) => key.split(":")[0]);
  }
  return keys || [];
};

export const curlItemsParser = (str) => {
  const splittedStr = str.split(":");
  const key = splittedStr[0].replace("|", "");
  const placeholder = splittedStr[1].replace("|", "");
  return { key, placeholder };
};

export const curlValidator = (str) => {
  const regex = new RegExp("^([a-zA-Z])[a-zA-Z1-9-_]*$");
  const keys = curlParser(str);
  let isValid = keys.every((key) => regex.test(key));
  return isValid;
};

export const shamsi2Miladi = (timeStamp) => {
  var parts = timeStamp.split(/\/| /);
  var jy, jm, jd;
  jy = parseInt(parts[0]);
  jm = parseInt(parts[1]);
  jd = parseInt(parts[2]);
  var sal_a, gy, gm, gd, days;
  jy += 1595;
  days =
    -355668 +
    365 * jy +
    ~~(jy / 33) * 8 +
    ~~(((jy % 33) + 3) / 4) +
    jd +
    (jm < 7 ? (jm - 1) * 31 : (jm - 7) * 30 + 186);
  gy = 400 * ~~(days / 146097);
  days %= 146097;
  if (days > 36524) {
    gy += 100 * ~~(--days / 36524);
    days %= 36524;
    if (days >= 365) days++;
  }
  gy += 4 * ~~(days / 1461);
  days %= 1461;
  if (days > 365) {
    gy += ~~((days - 1) / 365);
    days = (days - 1) % 365;
  }
  gd = days + 1;
  sal_a = [
    0,
    31,
    (gy % 4 === 0 && gy % 100 !== 0) || gy % 400 === 0 ? 29 : 28,
    31,
    30,
    31,
    30,
    31,
    31,
    30,
    31,
    30,
    31,
  ];
  for (gm = 0; gm < 13 && gd > sal_a[gm]; gm++) gd -= sal_a[gm];
  return new Date([gy + "/" + gm + "/" + gd] + " " + parts[3]);
};

export const convertArtoPr = (str) => {
  let string = str;
  const diff = {
    ة: "ه",
    ك: "ک",
    دِ: "د",
    بِ: "ب",
    زِ: "ز",
    ذِ: "ذ",
    شِ: "ش",
    سِ: "س",
    ى: "ی",
    ي: "ی",
    ئ: "ی",
    "٠": "۰",
    "١": "۱",
    "٢": "۲",
    "٣": "۳",
    "٤": "۴",
    "٥": "۵",
    "٦": "۶",
    "٧": "۷",
    "٨": "۸",
    "٩": "۹",
  };
  Object.entries(diff).map(
    ([key, value]) => (string = string.replaceAll(key, value))
  );
  return string;
};

export const insert = (arr, index, newItem) => [
  ...arr.slice(0, index),
  newItem,
  ...arr.slice(index),
];

export const strFieldExtractor = (str) => {
  const array = [];
  var patt = /<\/?[a-z][^>]*>/gi;
  const match = str.match(patt);
  match &&
    match
      .filter((input) => input.includes("input") && input)
      .map((input, index) => {
        array.push({ input });
      });
  return array;
};

export const compareArrays = (first, second) => {
  return (
    first.every((e) => second.includes(e)) &&
    second.every((e) => first.includes(e))
  );
};

export const responseParser = (apiResults, wantedResults) => {
  const resultsArray = [];
  const resultsObj = {};
  wantedResults.map(({ path, key }) => {
    const splitedRoute = path.split(".");
    let tempResult = { ...apiResults };
    _.times(splitedRoute.length, (index) => {
      tempResult = tempResult[splitedRoute[index]];
    });
    resultsArray.push({ [key]: tempResult });
    resultsObj[key] = tempResult;
  });
  return { obj: resultsObj, array: resultsArray };
};

export const makePreviosSteps = (steps) => {
  const data = [];
  steps.map((step) => {
    step.forms.map((form) => {
      form.inputs.map((input) => {
        const label = `گام: ${step.name} - فرم: ${form.label} - فیلد: ${input.label}`;
        const value = `${step._id}-${form._id}-${input.key}`;
        data.push({ label, value });
      });
    });
  });
  return data;
};

export const detectArrayType = (array) => {
  if (!Array.isArray(array)) {
    return "not an array";
  } else if (array.length === 0) {
    return "empty array";
  } else if (array[0] && typeof array[0] === "object") {
    return "array of objects";
  } else {
    return "array of primitives";
  }
};

const createArray = (len) => new Array(len);

export const findInputs = (schema) => {
  const inputs = [];

  schema.map((item) => {
    if (item.input === true) {
      inputs.push(item);
    } else {
      switch (item.type) {
        case "columns":
          let index = 0;

          for (const i of createArray(item.columns.length)) {
            const columnInputs = findInputs(item.columns[index].components);
            columnInputs.map((inp) => inputs.push(inp));

            index++;
          }

          for (let index = 0; index < item.columns.length; index++) {}
          break;
        case "content":
          console.log(item);
          break;
        default:
          const defaultInputs = findInputs(item.components);
          defaultInputs.map((inp) => inputs.push(inp));
          break;
      }
    }
  });

  return inputs;
};

export const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    if (!file) {
      reject(new Error("File is required."));
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result.split(",")[1];
      resolve(base64String);
    };

    reader.readAsDataURL(file);
  });
};

export const compressFile = async (file, maxSizeByte, compressionConf) => {
  let compressedfile = null;
  let error = false;

  try {
    compressedfile = await imageCompression(file, compressionConf);
    if (compressedfile?.size > maxSizeByte) {
      error = true;
    }
  } catch (error) {
    error = true;
  }

  return { file: compressedfile, error };
};

export const uploadFiles = async (fileInputs, fields = {}, maxSizeKB = 500) => {
  return new Promise(async (resolve, reject) => {
    const files = [];
    let errorMessage = {};

    try {
      for (const input of fileInputs) {
        const key = input?.key;
        const data = [];

        const maxSizeByte = KBToByte(maxSizeKB);
        const maxSizeMB = KBToMB(maxSizeKB * 0.9);

        for (const orgFile of input?.files) {
          let file = orgFile;
          // file["key"] = key;

          //Handle if file must be compressed
          if (file?.size > maxSizeByte) {
            compressionConf.maxSizeMB = maxSizeMB;
            const compressResponse = await compressFile(
              orgFile,
              maxSizeByte,
              compressionConf
            );
            if (compressResponse?.error) {
              resolve({
                files: [],
                isSuccess: false,
                errorMessage: {
                  title: "در فشرده سازی فایل شما مشکلی پیش آمده است",
                  description: `لطفا نسبت به کاهش حجم فایل تا ${maxSizeKB} کیلوبایت اقدام نمایید`,
                  status: "error",
                },
              });
            } else {
              file = compressResponse.file;
            }
          }

          try {
            const formBodyData = new FormData();
            formBodyData.append("file", file, file.name);
            // formBodyData.append("fileMaxSize", maxSizeByte);

            const uploadResponse = await uploadFile(formBodyData);
            data.push(uploadResponse.file._id);
          } catch (error) {
            console.log("error", error);
            errorMessage = {
              title:
                error?.response?.data?.message ||
                "آپلود فایل به مشکل خورده است",
              status: "error",
            };
            if (error?.response?.data?.errorCode === "LIMIT_FILE_SIZE") {
              errorMessage.description = `حجم فایل ${file.name} در فیلد "${fields[key]["label"]}" بیش از حد مجاز می باشد`;
            }
            throw errorMessage;
          }
        }

        if (data?.length > 0) {
          files.push({ key, data });
        }
      }

      const isSuccess = Object.keys(errorMessage).length === 0;

      if (!isSuccess) {
        for (const { data } of files) {
          for (const _id of data) {
            await deleteFile(_id);
          }
        }
      }

      resolve({
        files,
        isSuccess,
        errorMessage,
      });
    } catch (error) {
      reject(error);
    }
  });
};

export const flattenFilters = (filtersObj, columns) => {
  const filters = {};

  Object.entries(filtersObj)
    .filter(([key, value]) => typeof value === "string" && value != "")
    .map(([key, value]) => (filters[key] = value));
  Object.entries(filtersObj)
    .filter(([key, value]) => value)
    .filter(
      ([key, value]) =>
        typeof value === "object" && Object.values(value)[0] != undefined
    )
    .map(([key, value]) => {
      const [nestedKey, nestedValue] = Object.entries(value)[0];
      let objectKey;
      let objectValue;

      if (nestedValue.hasOwnProperty("value") && nestedKey === "label") {
        objectKey = key;
        objectValue = nestedValue?.value;
      } else {
        objectKey = `${key}.${nestedKey}`;
        objectValue =
          typeof nestedValue === "object" ? nestedValue?.value : nestedValue;
      }

      filters[objectKey] = objectValue;
    });

  return filters;
};

export const sabtahvalStatusLabel = (status) => {
  const labels = {
    alive: "در قید حیات",
    dead: "فوت شده",
    unknown: "نامشخص",
  };
  return labels[status] || "";
};

export const KBToByte = (KB) => KB * 1024;

export const KBToMB = (KB) => KB / 1024;

export const ray = new RayID("lower");

export const intRay = new RayID("digit");
