import { API, URL } from "src/config/api";

export const refreshToken = async (refreshToken) => {
  try {
    const response = await API.post(URL.auth.token.refresh, { refreshToken });
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject({ err, status: err?.response?.status });
  }
};
