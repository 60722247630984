const routes = [
  {
    directory: "home",
    url: "",
    private: false,
    index: true,
  },
  {
    directory: "single-pattern",
    url: "patterns",
    private: false,
    index: true,
  },
  // {
  //   directory: "form-builder",
  //   url: "form-builder",
  //   params: ":formId/:proccessId",
  //   private: true,
  //   index: false,
  // },
  // {
  //   directory: "form-renderer",
  //   url: "form-renderer",
  //   params: ":formId/:proccessId",
  //   private: true,
  //   index: false,
  // },
  // {
  //   directory: "root",
  //   url: "",
  //   private: false,
  //   index: true,
  // },
  // {
  //   directory: "500-error",
  //   url: "500",
  //   private: false,
  //   index: false,
  // },
  // {
  //   directory: "registration",
  //   url: "auth",
  //   params: ":mode",
  //   private: false,
  //   index: false,
  // },
  // {
  //   directory: "query-builder",
  //   url: "query-builder",
  //   private: false,
  //   index: false,
  // },
  // {
  //   directory: "dashboard",
  //   url: "dashboard",
  //   key: "dashboard",
  //   private: true,
  //   index: false,
  //   children: [
  //     {
  //       directory: "dashboard",
  //       url: "dashboard/requests",
  //       key: "requests",
  //       children: [
  //         {
  //           directory: "create-request",
  //           url: "create-request",
  //           key: "create-request",
  //         },
  //         {
  //           directory: "requests",
  //           url: "requests",
  //           key: "requests",
  //           params: ":type",
  //           availableParams: ["all", "role-related"],
  //         },
  //       ],
  //       priority: 1,
  //     },
  //     {
  //       directory: "dashboard",
  //       url: "dashboard/profile",
  //       key: "profile",
  //       children: [
  //         {
  //           directory: "user-info",
  //           url: "user-info",
  //           key: "profileUserInfo",
  //         },
  //         {
  //           directory: "personel-info",
  //           url: "personel-info",
  //           key: "profilePersonelInfo",
  //         },
  //         {
  //           directory: "wife-info",
  //           url: "wife-info",
  //           key: "profileWifeInfo",
  //         },
  //         {
  //           directory: "children-info",
  //           url: "children-info",
  //           key: "profileChildrenInfo",
  //         },
  //       ],
  //       priority: 2,
  //     },
  //     {
  //       directory: "dashboard",
  //       url: "dashboard/batch-actions",
  //       key: "batch-actions",
  //       children: [
  //         {
  //           directory: "batch-actions",
  //           url: "batch-actions",
  //           key: "batch-actions",
  //           params: ":type",
  //           availableParams: ["import", "export"],
  //         },
  //       ],
  //       priority: 11,
  //     },
  //     {
  //       directory: "users",
  //       url: "users",
  //       key: "users",
  //       priority: 3,
  //     },
  //     {
  //       directory: "permissions",
  //       url: "permissions",
  //       key: "permissions",
  //       priority: 4,
  //     },
  //     {
  //       directory: "roles",
  //       url: "roles",
  //       key: "roles",
  //       priority: 5,
  //     },
  //     {
  //       directory: "offices",
  //       url: "offices",
  //       key: "offices",
  //       priority: 6,
  //     },
  //     {
  //       directory: "proccesses",
  //       url: "proccesses",
  //       key: "proccesses",
  //       priority: 7,
  //     },
  //     {
  //       directory: "instalments",
  //       url: "instalments",
  //       key: "instalments",
  //       priority: 10,
  //     },
  //     {
  //       directory: "config",
  //       url: "config",
  //       key: "config",
  //       priority: 12,
  //     },
  //     {
  //       directory: "customlist",
  //       url: "customlist",
  //       key: "customlist",
  //       priority: 14,
  //     },
  //     {
  //       directory: "reports",
  //       url: "reports",
  //       key: "reports",
  //       priority: 15,
  //     },
  //   ],
  // },
];

export default routes;
