import { API, URL } from "src/config/api";

export const getData = async (obj) => {
  let url = URL.users;

  try {
    const response = await API.get(url, { params: obj });

    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject({ err, status: err.response.status });
  }
};

export const getSingle = async (id) => {
  try {
    const response = await API.get(`${URL.users}/${id}`);
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject(err.response.data);
  }
};

export const getUserDbFields = async (id) => {
  try {
    const response = await API.get(`${URL.users}/db-fields`);
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject(err.response.data);
  }
};

export const updateUser = async (data, id) => {
  try {
    const response = await API.put(`${URL.users}/${id}`, data);
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject(err.response.data);
  }
};

export const updatePassword = async (data, id) => {
  try {
    const response = await API.put(`${URL.users}/forgot/${id}`, data);
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject(err.response.data);
  }
};

export const deleteData = async (id) => {
  try {
    const response = await API.delete(`${URL.users}/${id}`);
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject(err);
  }
};
