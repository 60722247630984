import React, { useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

//redux
import { useDispatch, useSelector } from "react-redux";
import { getAppData } from "src/redux/app/actions";

//pages
import routes from "./routes";
import ProtectedRoute from "src/routes/protected-route";

//components
import Loader from "src/components/loader";
import ErrorPage from "src/components/404-error";

//loadable
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";

//helper
import { setApiHeaders } from "src/helper";

//config
import config from "src/config";

//css
import "react-day-picker/dist/style.css";

// console.log("test");

const LoadablePage = loadable(
  ({ directory, user, path }) => {
    setApiHeaders({
      userId: user && user.data ? user.data._id : null,
      roleLabel: user && user.data ? user.data.role.value : null,
      roleId: user && user.data ? user.data.role._id : null,
      officeId: user && user.data ? user.data.office._id : null,
      bankId: user && user.data ? user.data.bankId : null,
      path,
    });

    // console.log(`src/pages/${directory}`);
    return pMinDelay(import(`src/pages/${directory}`), 0);
  },
  {
    cacheKey: (props) => props.directory,
  }
);

const getIndexRoute = routes.filter((route) => route.index === true);
export const indexRoute = getIndexRoute ? getIndexRoute[0] : routes[0];

const App = () => {
  const dispatch = useDispatch();
  const { app, user } = useSelector((state) => ({
    app: state.app,
    user: state.user,
  }));

  const [router, setRouter] = useState(null);

  useEffect(() => {
    const savedAppVersion = localStorage.getItem("appVersion");
    if (!savedAppVersion || savedAppVersion != config?.version) {
      localStorage.clear();
      localStorage.setItem("appVersion", config?.version);
    }
    dispatch(getAppData());
  }, []);

  useEffect(() => {
    if (!app.loading) {
      setRouteConfig();
    }
  }, [app]);

  const setRouteConfig = () => {
    const allRoutes = [...routes];
    if (!config.production) {
      allRoutes.push({
        directory: "test",
        url: "1375133900",
        private: false,
        index: false,
      });
    }
    let routeConfig = allRoutes;
    if (!user.isAuthenticated) {
      routeConfig = routeConfig.filter(
        (route) => route.private === user.isAuthenticated
      );
    }
    const routesList = generateRoutes(routeConfig);
    console.log(routesList);
    setRouter(createBrowserRouter(routesList));
  };

  const makeRouteConfig = (route, parent) => {
    let routeObj = {};

    let path = `/${route.url}`;
    let directory = route.directory;

    if (parent) {
      path = `/${parent.url}/${route.url}`;
      if (route.hasOwnProperty("params")) {
        path = `/${parent.url}/${route.params}`;
      }
      directory = `${parent.directory}/pages/${route.directory}`;
      routeObj = {
        path,
        element: createRoute(path, parent.directory, parent.private),
        children: [
          {
            path,
            element: createRoute(path, directory, parent.private),
          },
        ],
        errorElement: <ErrorPage />,
      };
    } else {
      if (route.hasOwnProperty("params")) {
        path = path + `/${route.params}`;
      }
      routeObj = {
        path,
        element: createRoute(path, directory, route.private),
        errorElement: <ErrorPage />,
      };
    }

    return routeObj;
  };

  const generateRoutes = (routesArray, parent = null) => {
    const allRoutes = [];

    routesArray.map((route) => {
      const nestedRoute = route.hasOwnProperty("children");
      if (nestedRoute) {
        let childRoutes = route.children;
        if (route.key === "dashboard") {
          childRoutes = childRoutes.filter(({ key }) =>
            user?.data?.role?.permissions
              .map(({ value }) => value)
              .includes(key)
          );
        }
        const routeConfig = generateRoutes(childRoutes, route);
        allRoutes.push(...routeConfig);
      } else {
        const routeConfig = makeRouteConfig(route, parent || false);
        allRoutes.push(routeConfig);
      }
    });

    return allRoutes;
  };

  const createRoute = (path, directory, isPrivate) => {
    let routeElement;
    if (isPrivate) {
      routeElement = (
        <ProtectedRoute>
          <LoadablePage
            path={path}
            user={user}
            fallback={<Loader />}
            directory={directory}
          />
        </ProtectedRoute>
      );
    } else {
      routeElement = (
        <LoadablePage
          user={user}
          path={path}
          fallback={<Loader />}
          directory={directory}
        />
      );
    }
    return routeElement;
  };

  return !router ? <Loader /> : <RouterProvider router={router} />;
};

export default App;
