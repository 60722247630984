import { SET_APP_DATA, SET_APP_LOADING } from "src/redux/constants";

const initialState = { loading: true };

export default function (state = initialState, action) {
  const storedData = { ...state };
  const payloadData = action.payload;
  switch (action.type) {
    case SET_APP_DATA:
      return { ...storedData, ...payloadData };
    case SET_APP_LOADING:
      storedData.loading = payloadData;
      return storedData;
    default:
      return state;
  }
}
