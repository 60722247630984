import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";

//components
import App from "./app";
import theme from "src/theme/theme";
import "src/styles/index.css";
import "src/styles/formio.full.min.css";
import "react-image-crop/dist/ReactCrop.css";
import "react-quill/dist/quill.snow.css";

//redux
import { Provider } from "react-redux";
import store from "src/redux/store";

const root = createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <ChakraProvider theme={theme} resetCss={false}>
      <App />
    </ChakraProvider>
  </Provider>
);
