import jwt_decode from "jwt-decode";

//services
import { getUserDbFields } from "src/services/api/users";
import { getData as getCustomLists } from "src/services/api/custom-lists";

//helper
import { setApiHeaders } from "src/helper";

//config
import setAuthToken from "src/config/api/setAuthToken";
import { API, URL } from "src/config/api";

//redux
import {
  SET_CURRENT_USER,
  LOG_OUT_USER,
  SET_USER_ERROR,
  SET_USER_LOADING,
  SET_USER_DATA,
  SET_APP_LOADING,
} from "src/redux/constants";

const authUrls = URL.auth;

export const setCurrentUser =
  (token = null, refreshToken = null) =>
  async (dispatch) => {
    localStorage.setItem("userToken", token);
    if (refreshToken) {
      localStorage.setItem("userRefreshToken", refreshToken);
    }

    setAuthToken(token);

    const decoded = jwt_decode(token);

    try {
      const response = await API.get(`${URL.users}/${decoded.id}`);
      const userInfo = response.data;

      try {
        const fields = await getUserDbFields();
        const customListsResponse = await getCustomLists();

        localStorage.setItem(
          "userDataKeys",
          JSON.stringify(fields.userDataKeys)
        );
        localStorage.setItem(
          "userInfoKeys",
          JSON.stringify(fields.userInfoKeys)
        );
        localStorage.setItem(
          "userPersonelKeys",
          JSON.stringify(fields.userPersonelKeys)
        );
        localStorage.setItem(
          "userWifeKeys",
          JSON.stringify(fields.userWifeKeys)
        );
        localStorage.setItem(
          "userChildKeys",
          JSON.stringify(fields.userChildKeys)
        );

        localStorage.setItem(
          "costomLists",
          JSON.stringify(customListsResponse.customLists)
        );
      } catch (error) {}

      setApiHeaders({
        uid: userInfo._id,
        path: response.config.headers.path || "/",
        role: userInfo.role.value,
      });
      dispatch({
        type: SET_CURRENT_USER,
        payload: userInfo,
      });
      return Promise.resolve(userInfo);
    } catch (err) {
      console.log(err);
      if (
        err.response.statusText === "Unauthorized" ||
        err.response.status === 401
      ) {
        dispatch(logoutUser());
      }
      dispatch({
        type: SET_USER_ERROR,
        payload: err.response,
      });
    }
  };

export const registerUser =
  (data, loginAfterRegister = true) =>
  async (dispatch) => {
    try {
      const response = await API.post(authUrls.credentials.register, data);
      if (loginAfterRegister) {
        response.then(() => dispatch(loginCredentials(data)));
      } else {
        return Promise.resolve(response);
      }
    } catch (err) {
      dispatch({
        type: SET_USER_ERROR,
        payload: err.response,
      });
      return Promise.reject(err.response.data);
    }
  };

export const updateUser = (data, id) => async (dispatch) => {
  try {
    const response = await API.put(`${URL.users}/${id}`, data);
    dispatch({
      type: SET_USER_DATA,
      payload: response.data.data,
    });
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err.response.data);
  }
};

export const loginCredentials = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_USER_LOADING,
      payload: true,
    });
    const response = await API.post(authUrls.credentials.login, data);

    const { token, refreshToken } = response.data;
    await dispatch(setCurrentUser(token, refreshToken));
  } catch (err) {
    dispatch({
      type: SET_USER_ERROR,
      payload: err.response,
    });
    return Promise.reject(err.response.data);
  }
};

export const loginOTP = (data, rayId) => async (dispatch) => {
  try {
    dispatch({
      type: SET_USER_LOADING,
      payload: true,
    });

    const response = await API.post(authUrls.otp.login, data, {
      headers: { ray: rayId },
    });

    return Promise.resolve(response.data);
  } catch (err) {
    dispatch({
      type: SET_USER_ERROR,
      payload: err.response,
    });
    return Promise.reject(err.response.data);
  }
};

export const checkOTP = (data, uid) => async (dispatch) => {
  try {
    dispatch({
      type: SET_USER_LOADING,
      payload: true,
    });

    const response = await API.post(authUrls.otp.check, data, {
      headers: {
        id: uid,
      },
    });

    const { token, refreshToken } = response.data;

    await dispatch(setCurrentUser(token, refreshToken));
  } catch (err) {
    dispatch({
      type: SET_USER_ERROR,
      payload: err.response,
    });
    return Promise.reject(err.response.data);
  }
};

export const logoutUser = () => (dispatch) => {
  dispatch({
    type: SET_APP_LOADING,
    payload: true,
  });
  localStorage.removeItem("userToken");
  localStorage.removeItem("userModelKeys");
  localStorage.removeItem("userRefreshToken");
  setAuthToken(false);
  dispatch({
    type: LOG_OUT_USER,
  });
  dispatch({
    type: SET_APP_LOADING,
    payload: false,
  });
};

export const loginSSO = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_USER_LOADING,
      payload: true,
    });

    const response = await API.post(authUrls.sso.login, data);

    const { token, refreshToken } = response.data;

    await dispatch(setCurrentUser(token, refreshToken));
  } catch (err) {
    dispatch({
      type: SET_USER_ERROR,
      payload: err.response,
    });
    return Promise.reject(err.response.data);
  }
};
