import { SET_APP_DATA, SET_APP_LOADING } from "src/redux/constants";
import { setCurrentUser } from "src/redux/user/actions";

import { getData as getPermissions } from "src/services/api/permissions";
import { getData as getRoles } from "src/services/api/roles";
import { getSubData as getOffices } from "src/services/api/offices";
import { getData as getProccesses } from "src/services/api/proccesses";

import { setApiHeaders } from "src/helper";

export const getAppData = () => async (dispatch) => {
  let appData = {};
  const token = localStorage.getItem("userToken");
  setApiHeaders({ uid: null, role: null, path: "/" });
  if (token) {
    await dispatch(setCurrentUser(token))
      .then(async () => {
        await getPermissions()
          .then((data) => (appData.permissions = data.permissions))
          .catch((err) => {});
        await getRoles()
          .then((data) => (appData.role = data.roles))
          .catch((err) => {});
        await getOffices()
          .then((data) => (appData.office = data))
          .catch((err) => {});
        await getProccesses()
          .then((data) => (appData.proccess = data.proccesses))
          .catch((err) => {});
        dispatch({
          type: SET_APP_DATA,
          payload: appData,
        });
      })
      .catch((error) => {});
  }
  dispatch({
    type: SET_APP_LOADING,
    payload: false,
  });
};
