import { mode } from "@chakra-ui/theme-tools";
import { colors } from "src/theme/colors";

export const globalStyles = {
  initialColorMode: "light",
  useSystemColorMode: true,
  direction: "rtl",
  colors,
  fonts: {
    heading: "irancell-bold",
    body: "irancell-regular",
  },
  styles: {
    global: (props) => ({
      body: {
        bg: mode("white", colors.navy.dark)(props),
      },
    }),
  },
};
