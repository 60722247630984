import { API, URL } from "src/config/api";

export const getData = async (obj) => {
  let url = URL.permissions;
  if (obj) {
    let queryString = "";
    Object.entries(obj).map(
      ([key, value]) => (queryString += `&${key}=${value}`)
    );
    queryString = queryString.substring(1);
    queryString = `?${queryString}`;
    url += queryString;
  }

  try {
    const response = await API.get(url);
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject({ err, status: err.response.status });
  }
};

export const createData = async (data) => {
  try {
    const response = await API.post(`${URL.permissions}`, data);
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const updateData = async (id, data) => {
  try {
    const response = await API.put(`${URL.permissions}/${id}`, data);
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteData = async (id) => {
  try {
    const response = await API.delete(`${URL.permissions}/${id}`);
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject(err);
  }
};
