import { mode } from "@chakra-ui/theme-tools";

export const textAreaStyles = {
  components: {
    Textarea: {
      variants: {
        primary: (props) => ({
          bg: mode("sky.light", "navy.light")(props),
          color: mode("black", "white")(props),
          width: "100%",
          height: "200px",
          padding: "20px",
          textAlign: "right",
          lineHeight: "28px",
          borderRadius: "xl",
          _placeholder: { color: "#747474" },
          _disabled: {
            opacity: 0.5,
          },
        }),
      },
    },
  },
};
