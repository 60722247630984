export const IMAGES = {
  logoLight: require("src/images/files/logo.png"),
  logoDark: require("src/images/files/logo-2.png"),
  login: require("src/images/files/login-bg.jpg"),
  logo: require("src/images/files/logo2.png"),
  noData: require("src/images/files/no-data.png"),
  empty: require("src/images/files/404.png"),
  "500-error": require("src/images/files/500.png"),
  themeLight: require("src/images/files/theme-light.jpg"),
  themeDark: require("src/images/files/theme-dark.jpg"),
  cover: require("src/images/files/cover.png"),
  invest1: require("src/images/files/invest1.png"),
  invest2: require("src/images/files/invest2.png"),
  product: require("src/images/files/product.jpg"),
  productLogo: require("src/images/files/product-logo.jpg"),
  goals1: require("src/images/files/goals1.png"),
  goals2: require("src/images/files/goals2.png"),
  goals3: require("src/images/files/goals3.png"),
};
