//USER
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const LOG_OUT_USER = "LOG_OUT_USER";
export const SET_USER_ERROR = "SET_USER_ERROR";
export const SET_USER_LOADING = "SET_USER_LOADING";
export const SET_USER_DATA = "SET_USER_DATA";

//APP
export const SET_APP_DATA = "SET_APP_DATA";
export const SET_APP_LOADING = "SET_APP_LOADING";
